export class Backend {
  public static index = ((resource: string, query: any) => {
    const { public: { api_v1: baseURL } } = useRuntimeConfig()
    return $fetch(resource, { baseURL, query })
  })

  public static get = ((resource: string, slug: string) => {
    const { public: { api_v1: baseURL } } = useRuntimeConfig()
    return $fetch(`${resource}/${slug}`, { baseURL })
  })

  public static post = ((resource: string, params: any) => {})

  public static update = (() => {})
}
